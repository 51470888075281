import React from 'react';
import classNames from 'classnames';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Typography, Grid, Dialog, Slide, Hidden } from '@material-ui/core';
import { KeyboardArrowRightSharp } from '@material-ui/icons';

import ensureGtag from '../helpers/ensureGtag';

import { HomePage } from '../imageURLs';

import { enlargePercent, CenterStyle } from '../theme';
import { TVT_FB_URL, TVT_GOOGLE_URL } from '../constants';
import SocialReview from '../components/SocialReview/SocialReview';

import Hero from '../components/Hero/Hero';
import SEO from '../components/SEO';
import Image from '../components/Image';
import Wizard from '../components/Wizard/Wizard';
import CarouselSlider from '../components/Carousel/CarouselSlider';
import { withQuoteWizard } from '../components/withQuoteWizard';
import CTAButton from '../components/CTAButton/CTAButton';

import { pxToRem } from '../theme';

import { wizardConfig } from '../constants';

const sliderItems = [
  {
    title: 'Our lawns are fresh',
    body: 'We only ever harvest on the day of delivery.',
    imagePath: HomePage.slide.image1,
  },
  {
    title: 'Our service is first class',
    body: 'We set the standard for customer service.',
    imagePath: HomePage.slide.image2,
  },
  {
    title: 'Superior quality',
    body: 'Quality lawns with a new standard in satisfaction.',
    imagePath: HomePage.slide.image3,
  },
];

const Transition = props => <Slide direction="up" {...props} />;

const styles = theme =>
  createStyles({
    cta: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'none',
      padding: 20,
      border: 'none',
      width: '100%',
      height: 80,
      [theme.breakpoints.up(400)]: {
        height: 120,
      },
      [theme.breakpoints.up(960)]: {
        display: 'none',
      },
    },
    ctaBlue: {
      backgroundColor: '#181A38',
    },
    ctaGreen: {
      backgroundColor: '#1BB25F',
    },
    ctaText: {
      fontSize: '1.5rem',
      color: '#fff',
      fontWeight: 600,
      marginTop: -3,
      paddingLeft: 32,
      [theme.breakpoints.up(400)]: {
        fontSize: pxToRem(32),
      },
    },
    icon: {
      color: '#fff',
      fontSize: '2.125rem',
      [theme.breakpoints.up(400)]: {
        fontSize: `${enlargePercent(2.125)}rem`,
      },
    },
    mobileSocial: {
      display: 'flex',
      [theme.breakpoints.up(960)]: {
        display: 'none',
      },
    },
    desktopSocial: {
      display: 'none',
      ...CenterStyle,
      [theme.breakpoints.up(960)]: {
        display: 'flex',
      },
    },
    desktopSocialWrap: {
      backgroundColor: '#e9e9e9',
    },
    desktopReview: {
      backgroundColor: '#e9e9e9',
      '& > div': {
        padding: '90px 0',
      },
    },
    profileImageWrap: {
      width: 100,
      minWidth: 100,
      height: 100,
      borderRadius: '50%',
      overflow: 'hidden',
      willChange: 'transform',
      border: 0,
      margin: '0 10px',
    },
    profileImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    reviewTitle: {
      fontSize: pxToRem(27),
      lineHeight: 1.2,
      fontWeight: 600,
      paddingBottom: 15,
    },
    reviewBody: {
      color: theme.palette.primary.main,
      fontSize: pxToRem(18),
      paddingRight: 30,
    },
    desktopSocialReviewWrap: {
      backgroundColor: '#ECBAAB',
    },
    desktopSocialReview: {
      ...CenterStyle,
      padding: '100px 20px 40px',
      [theme.breakpoints.up(960)]: {
        padding: '80px 20px',
      }
    },
    customerImageWrap: {
      width: 210,
      height: 210,
      borderRadius: '50%',
      overflow: 'hidden',
      willChange: 'transform',
      marginBottom: 20,
    },
    customerImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    customerReviewTitle: {
      fontSize: pxToRem(45),
      marginBottom: 35,
      [theme.breakpoints.up('md')]: {
        marginBottom: 25,
      },
      [theme.breakpoints.between('md', 'lg')]: {
        fontSize: pxToRem(35),
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: pxToRem(45),
      }
    },
    socialNetsWrap: {
      padding: '40px 0',
      [theme.breakpoints.up(960)]: {
        padding: 0,
      }
    }
  });

class IndexPage extends React.Component {
  state = {
    isLawnWizardOpen: false,
  };

  toggleLawnWizard = (stepNumber, totalStep = '') => {
    this.setState(prevState => {
      const isOpen = !prevState.isLawnWizardOpen;

       if (!isOpen) { // on close only
        totalStep = totalStep && `/${totalStep}`;
        ensureGtag('event', `wizard_closed_on_${stepNumber}${totalStep}_step`, {
          event_category: 'Wizard',
        });
      }

       return {
        isLawnWizardOpen: isOpen,
      };
    });
  };

  render() {
    const { classes, toggleQuoteComp, children } = this.props;
    const { isLawnWizardOpen } = this.state;

    return (
      <React.Fragment>
        <SEO
          title="Twin View Turf - Producer of Australia’s Best Lawns"
          keywords={[
            `Turf`,
            `Lawns`,
            `Best Lawns`,
            `Australia’s Best Lawns`,
            `lawn producer`,
          ]}
        />
        <Hero
          heroImage={HomePage.hero}
          heroText="Home to Australia’s Best Lawns"
          toggleLawnWizard={this.toggleLawnWizard}
        >
          <button
            className={classNames(classes.cta, classes.ctaBlue)}
            onClick={toggleQuoteComp}
          >
            <Typography className={classes.ctaText}>
              Get a quick quote
            </Typography>
            <KeyboardArrowRightSharp className={classes.icon} />
          </button>

          <button
            className={classNames(classes.cta, classes.ctaGreen)}
            onClick={this.toggleLawnWizard}
          >
            <Typography className={classes.ctaText}>Lawn Wizard</Typography>
            <KeyboardArrowRightSharp className={classes.icon} />
          </button>
        </Hero>

        <Grid container direction="column" className={classes.mobileSocial}>
          <CarouselSlider items={sliderItems} />
        </Grid>

        <Grid container className={classes.desktopSocialWrap}>
          <Grid container direction="column" className={classes.desktopSocial}>
            <Grid
              item
              container
              direction="row"
              className={classes.desktopReview}
              justify="space-around"
            >
              {sliderItems.map((i, index) => (
                <Grid
                  key={String(index)}
                  item
                  container
                  xs={4}
                >
                  <Grid item xs={4} container justify="flex-end">
                    <div className={classes.profileImageWrap}>
                      <Image
                        relativePath={i.imagePath}
                        className={classes.profileImage}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={8} style={{ paddingLeft: 10 }}>
                    <Typography
                      variant="subtitle1"
                      component="p"
                      color="primary"
                      className={classes.reviewTitle}
                    >
                      {i.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      component="p"
                      color="primary"
                      className={classes.reviewBody}
                    >
                      {i.body}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          className={classes.desktopSocialReviewWrap}
        >
          <Grid
            container
            direction="row"
            className={classes.desktopSocialReview}
            justify="space-between"
          >
            <Grid item container direction="column" md={3} alignItems="center">
              <Grid item className={classes.customerImageWrap}>
                <Image
                  className={classes.customerImage}
                  relativePath={HomePage.testimonials.main}
                />
              </Grid>
              <Grid item>
                <Typography
                  className={classes.customerReviewTitle}
                  variant="h2"
                  color="primary"
                >
                  See what our customers say
                </Typography>
              </Grid>
              <Hidden smDown>
                <Grid item>
                  <CTAButton
                    ctaHref="/testimonials"
                    ctaText="Read real reviews"
                    inverted
                    btnStyles={{
                      // backgroundColor: '#181A38',
                      textAlign: 'center',
                    }}
                  />
                </Grid>
              </Hidden>
            </Grid>
            <Grid container item
              className={classes.socialNetsWrap}
              spacing={16}
              md={8}
              justify="center"
              alignItems="stretch"
            >
              <Grid item container sm={6}>
                <SocialReview viewMore={false} single platform="google" source={TVT_GOOGLE_URL} />
              </Grid>
              <Grid item container sm={6}>
                <SocialReview viewMore={false} single platform="facebook" source={TVT_FB_URL} />
              </Grid>
            </Grid>
            <Hidden mdUp>
              <Grid container item justify="center">
                <CTAButton
                  ctaHref="/testimonials"
                  ctaText="Read real reviews"
                  inverted
                  btnStyles={{
                    // backgroundColor: '#181A38',
                    textAlign: 'center',
                  }}
                />
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
        <Dialog
          fullScreen
          open={isLawnWizardOpen}
          TransitionComponent={Transition}
          PaperProps={{
            style: { backgroundColor: '#e9e9e9' },
          }}
        >
          <Wizard
            header="Lawn Wizard"
            questions={wizardConfig.steps}
            results={wizardConfig.results}
            closeFn={this.toggleLawnWizard}
            openQuote={toggleQuoteComp}
          />
        </Dialog>
        {children}
      </React.Fragment>
    );
  }
}

export default withQuoteWizard(
  withStyles(styles, { withTheme: true })(IndexPage)
);
