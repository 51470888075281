import React from 'react';
import classNames from 'classnames';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import { pxToRem } from '../theme';

const styles = (theme) =>
  createStyles({
    title: {
      fontSize: pxToRem(28),
      textAlign: 'center',
      padding: '20px 40px',
      [theme.breakpoints.up(400)]: {
        padding: '40px 40px 20px',
        fontSize: pxToRem(45),
      }
    },
    invertedFontColor: {
      color: '#fff',
    },
  });

const BlueTitle = ({ classes, title, inverted = false }) => (
  <Grid item>
    <Typography
      className={classNames(classes.title, { [classes.invertedFontColor]: inverted })}
      variant="h2"
      color="primary"
    >
      {title}
    </Typography>
  </Grid>
);

export default withStyles(styles, { withTheme: true })(BlueTitle);




