import React from 'react';
import classNames from 'classnames';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';

import { pxToRem } from '../../theme';

import BlueTitle from '../BlueTitle';
import Image from '../Image';

const styles = theme =>
  createStyles({
    root: {
      backgroundColor: '#E9E9E9',
      paddingBottom: 30,
      maxHeight: '88vh',
    },
    innerImageWrap: {
      width: 180,
      height: 180,
      borderRadius: '50%',
      overflow: 'hidden',
      willChange: 'transform',
      border: 0,
      [theme.breakpoints.up(400)]: {
        width: 205,
        height: 205,
      },
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    copy: {
      padding: '0 50px',
      minHeight: '48px',
      maxWidth: 340,
      margin: '0 auto',
    },
    invertedBackground: {
      backgroundColor: '#181A38',
    },
    invertedFontColor: {
      color: '#fff',
    },
    largeImage: {
      width: 301,
      height: 301,
    },
    dot: {
      backgroundColor: '#181A38',
    },
    invertedDot: {
      backgroundColor: '#E9E9E9',
    },
    activeDot: {
      backgroundColor: '#1BB25F',
    },
    slideContentText: {
      textAlign: 'center',
      [theme.breakpoints.up(400)]: {
        fontSize: pxToRem(22)
      }
    }
  });

const Carousel = ({ classes, title, body = "", imagePath, inverted = false, largeImage = false }) => (
  <Grid
    container
    item
    justify="center"
    direction="column"
    className={classNames(classes.root, {
      [classes.invertedBackground]: inverted,
    })}
  >
    <Grid container item justify="center">
      <Grid item className={classNames(classes.innerImageWrap, { [classes.largeImage]: largeImage })}>
        <Image className={classNames(classes.image)} relativePath={imagePath} />
      </Grid>
    </Grid>
    <BlueTitle title={title} inverted={inverted} />
    <Grid item className={classNames(classes.copy)}>
      <Typography
        variant="body1"
        className={classNames(classes.slideContentText, {
          [classes.invertedFontColor]: inverted })
        }
      >
        {body}
      </Typography>
    </Grid>
  </Grid>
);

export default withStyles(styles, { withTheme: true })(Carousel);