import React from 'react';
import classnames from 'classnames';
import { Grid } from '@material-ui/core';
import { range } from 'lodash';
import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = theme =>
  createStyles({
    root: {
      padding: 8,
    },
    standardDot: {
      padding: 0,
      width: 20,
      height: 20,
      margin: 10,
      borderRadius: '50%',
      backgroundColor: '#181A38',
      border: 0,
      '&:focus': {
        outline: 0,
      }
    },
    dot: {
      backgroundColor: '#181A38',
    },
    invertedDot: {
      backgroundColor: '#E9E9E9',
    },
    activeDot: {
      backgroundColor: '#1BB25F',
    },
  });

const Stepper = ({ steps, activeStep, classes, inverted, onDotClick }) => (
  <Grid container direction="row" justify="center" alignItems="center" className={classes.root}>
    <Grid item>
      {range(steps).map((stepIndex) => (
        <button
          key={String(stepIndex)}
          type="button"
          onClick={() => onDotClick(stepIndex)}
          className={classnames(classes.standardDot,{
            [classes.dot]: (!inverted && activeStep !== stepIndex),
            [classes.invertedDot]: (inverted && activeStep !== stepIndex),
            [classes.activeDot]: activeStep === stepIndex,
          })}
        />
      ))}
    </Grid>
  </Grid>
);

export default withStyles(styles, { withTheme: true})(Stepper);